
    // import Vue from 'vue';
    import { defineComponent } from "vue";
    import vm from '@/main';
    import { Location } from 'vue-router/types/router.d';


    interface NavbarLocation {
        label: string;
        location: Location;
        englishLabel: string;
    }

    export default defineComponent({
        name: "navbar",
        components: {

        },
        data() {
            return {
                showSideNav: false,
            };
        },
        // watch: {
        //   "$store.state.profile"(newVal, oldVal) {
        //     if(newVal == null){
        //         this.isLogin()
        //         console.log("store.state.profile change: "+newVal)
        //     }
        //   },
        // },
        computed: {
            links(): NavbarLocation[] {
                // if (!this.$store.state.currentWorkspace) {
                //     return [];
                // }
                return [
                    {
                        location: {
                            name: 'CourseWorkspace',
                            // params: {
                            //     folderId: this.$store.state.currentWorkspace.courseFolders.local,
                            // },
                        },
                        label: '課程',
                        englishLabel: 'course'
                    },
                    {
                        location: {
                            name: 'QuizWorkspace',
                            // params: {
                            //     folderId: this.$store.state.currentWorkspace.quizFolders.local,
                            // },
                        },
                        label: '測驗',
                        englishLabel: 'quiz'

                    },{
                        location: {
                            name: 'SellPlanWorkspace',
                            // params: {
                            //     folderId: this.$store.state.currentWorkspace.quizFolders.local,
                            // },
                        },
                        label: '銷售方案',
                        englishLabel: 'sellPlan'

                    },
                    {
                        location: {
                            name: 'CourseCategoryWorkspace',
                            // params: {
                            //     folderId: this.$store.state.currentWorkspace.quizFolders.local,
                            // },
                        },
                        label: '分類',
                        englishLabel: 'category'

                    },
                ];
            },
            username(): string {
                if (this.$store.state.profile) {
                    return this.$store.state.profile.username;
                }
                return 'unknown';
            },
            // homepageLocation(): Location {
            //   if (this.$route.name !== 'Login' && this.$store.state.currentWorkspace) {
            //       return {
            //       name: 'QuizGroups',
            //       params: {
            //           folderId: this.$store.state.currentWorkspace.quizFolders.local,
            //       },
            //       };
            //   }
            //   return { name: 'Login' };
            // },

        },
        methods: {
            logout() {
                if (vm.$cookies.isKey('token')) {
                    vm.$cookies.remove('token');
                }
                if (vm.$cookies.isKey('sellPlanFolders')) {
                    vm.$cookies.remove('sellPlanFolders');
                }
                if (vm.$cookies.isKey('quizFolders')) {
                    vm.$cookies.remove('quizFolders');
                }
                if (vm.$cookies.isKey('courseFolders')) {
                    vm.$cookies.remove('courseFolders');
                }
                vm.$store.commit('resetState');
                console.log("logot")
                console.log("this.$store.state.profile: " + this.$store.state.profile)
                // console.log("this.$store.state.profile.email: " + this.$store.state.profile.email)
                // console.log("this.$store.state.profile.username: " + this.$store.state.profile.username)
                vm.$router.push('/login');
            },
            closeSideNav() {
                this.showSideNav = false;
            },
            isLogin() {
                // return true;
                console.log("this.$store.state.profile: " + this.$store.state.profile)
                // console.log("this.$store.state.profile.email: " + this.$store.state.profile.email)
                // console.log("this.$store.state.profile.username: " + this.$store.state.profile.username)
                if(Boolean(this.$store.state.profile)){
                    console.log("true")
                    return true
                }
                else{
                    console.log("false")

                    vm.$router.push('/login');
                    return false;
                }

            },
        },
    });
